// require.undef removes the existing hotspotPreview definition so that it can be redefined immediately afterwards
require.undef('hotspotPreview');
// another important module that handles the requesting, loading and rendering of preview images for hotspots
define('hotspotPreview',
    ['messaging', 'http', 'viewData', 'templateOptionsView', 'hotspotFormStateReader', 'menu', 'loadedTemplates', 'bottomBar', 'customImage'], function
        (messaging, http, viewData, templateOptionsView, hotspotFormStateReader, menu, loadedTemplates, bottomBar, customImage) {
	'use strict';

	// global vars for the module and constant dom selectors
	var projectUISelector = '.project-ui';
	var previewSelector = '.preview-container .preview';
	var zoomViewSelector = '.preview-container .zoom-view';
	var previewImageContainer = '.preview-container .zoom-preview-container';
	var currentHotspotSelector = '.preview-container .current-hotspot';
	var zoomOutLink = '.zoom-out-link';
	var showGuideLink = '.toggle-guide-link';
	var previewTimer = 0;
	var currentPerspective = {};
	var currentHotspot = {};
	var currentTemplate = {};
	var showGuide = false;
	var ajaxRequest = -1;
	var zoomedIn = false;

	// initialization function that subscribes to numerous pubsub topics and sets up some click handlers
	var init = function () {
		messaging.hotspotSelected.subscribe(hotspotSelected);
		messaging.hotspotPreviewRequested.subscribe(hotspotPreviewRequested);
		messaging.hotspotTemplateSelected.subscribe(hotspotTemplateSelected);
		messaging.applyPreview.subscribe(applyPreview);
		$('.customization-buttons .use-design').click(previewButtonClicked);
		$('.customization-buttons .use-text').click(previewButtonClicked);
		$('.customization-buttons .use-upload').click(previewButtonClicked);
		$(zoomOutLink).click(zoomOutLinkClicked);
        $(showGuideLink).click(showGuideLinkClicked);
       
	};

	// zoom out link click handler
	var zoomOutLinkClicked = function () {
		zoomedIn = false;
		$(previewSelector).removeClass('zoomed');
		$(projectUISelector).removeClass('perspective-mode hotspot-mode').addClass('perspective-mode');
		messaging.zoomedOut.publish();
	};

	// show preview guid link click handler
	var showGuideLinkClicked = function () {
		// this click handler is basically adds toggle functionality for field guides
		showGuide = !showGuide;
		var $showGuide = $(showGuideLink);
		if (showGuide) {
			// change the text of the link
			$showGuide.html('Hide Guide');
			bottomBar.guideDisabled();
		}
		else {
			// change the text of the link
			$showGuide.html('Show Guide');
			bottomBar.guideEnabled();
		}
		// request an updated hotspot preview
		hotspotPreviewRequested();
	};

	// hotspot selected handler
	var hotspotSelected = function (data) {
		// set private vars for this module
		zoomedIn = true;
		$(previewImageContainer).css('background-image', 'none');
		currentPerspective = data.perspective;
		currentHotspot = data.hotspot;
		// make the dom changes to zoom in the info
		$(previewSelector).removeClass('zoomed').addClass('zoomed');
		$(projectUISelector).removeClass('perspective-mode hotspot-mode').addClass('hotspot-mode');
		// request an updated hotspot preview
		hotspotPreviewRequested();
		$(currentHotspotSelector).html('[ Currently Designing: ' + data.hotspot.Label + ' ]');
		// gets the currently loaded template
		var template = getCurrentTemplate();
		if (template == null)
			$(zoomViewSelector).removeClass('customizing');
		else
            $(zoomViewSelector).addClass('customizing');

       
	};

	// this is a typical function that keeps hotspot preview requests to a minimum
	var hotspotPreviewRequested = function () {
		// if the ajaxRequest object was saved, then abort it (doesn't always do anything)
		if (ajaxRequest != -1) {
			ajaxRequest.abort();
			ajaxRequest = -1;
		}
		clearTimeout(previewTimer);
		previewTimer = setTimeout(generatePreview, 750);
	};

	// hotspot template selected handler
	var hotspotTemplateSelected = function (template) {
		var $zoomView = $(zoomViewSelector);
		$zoomView.removeClass('customizing').addClass('customizing');
		showGuide = true;
	};


	// apply preview handler
	// this is just like the generatePreview method, except this one saves a preview out to disk
	var applyPreview = function () {
		// this posts the relevant data to generate a hotspot preview
		var data = {
			BuildID: viewData.BuildID,
			ProjectID: viewData.ProjectID,
			FormState: JSON.stringify(hotspotFormStateReader.readHotspotFormState()),
			ShowGuide: showGuide,
			savePreview: true
		};
		http.post('/Build/Project/OneViewPreview', data).error(errorHandler);
	};

	// generate preview method
	// this calls the ajax endpoint to generate a preview
	var generatePreview = function () {
		// show the loading div at the top
		$(previewSelector).removeClass('loading').addClass('loading');
		var data = {
			BuildID: viewData.BuildID,
			ProjectID: viewData.ProjectID,
			FormState: JSON.stringify(hotspotFormStateReader.readHotspotFormState()),
			ShowGuide: showGuide
		};
		ajaxRequest = http.post('/Build/Project/OneViewPreview', data).error(errorHandler).done(previewGenerated);
	};

	// error handler for image generation ajax calls
	var errorHandler = function () {
		$(previewSelector).removeClass('loading');
		//alert('An error occurred while generating the preview.  An error notification \nhas been sent.  Please check your settings for any issues.');
	};

	// handles the response from the hotspot preview action, which is a base 64 byte array of the preview image
	var previewGenerated = function (response) {
		var $zoomView = $(zoomViewSelector);
		var template = getCurrentTemplate();
		// need to come back to this code and figure out what it does
		if (template != null) {
			if (template.FieldGuideImage && (template.FieldGuideImage != '')) {
				$zoomView.removeClass('has-guide').addClass('has-guide');
				bottomBar.guideAvailable();
				var $showGuide = $(showGuideLink);
				if (showGuide) {
					$showGuide.html('Hide Guide');
					bottomBar.guideDisabled();
				}
				else {
					$showGuide.html('Show Guide');
					bottomBar.guideEnabled();
				}
			}
			else {
				$zoomView.removeClass('has-guide');
				bottomBar.guideUnavailable();
			}
		}
		else
			$zoomView.removeClass('has-guide');
		// apply the response to the preview div background
		$(previewSelector).removeClass('loading');
		var $previewImage = $(previewImageContainer);
		$previewImage.css('background-image', 'url(\'data:image/png;base64,' + response + '\')');
        ajaxRequest = -1;



        if ($previewImage.find('.preview-image-wrapper').length == 0)
        {
            var $imageWrapper = $('<div>').addClass('preview-image-wrapper').css('visibility', 'hidden').css('opacity', 0);
            var $image = $('<img>').attr('src', 'data:image/png;base64,' + response);
            $imageWrapper.append($image);
            $previewImage.append($imageWrapper);
        }
       


        customImage.PreviewGenerationComplete();

	};

	// preview button doesn't exist anymore and this method should be called automatically, or simply deprecated altogether
	var previewButtonClicked = function () {
		var $this = $(this);
		var openTabSelector = '.menu .hotspot-view.open .menu-tab.open';
		if ($this.hasClass('use-design')) {
			$(openTabSelector).removeClass('open');
			$('.menu .hotspot-view.open .menu-tab.design').removeClass('open').addClass('open');
		}
		else if ($this.hasClass('use-text')) {
			$(openTabSelector).removeClass('open');
			$('.menu .hotspot-view.open .menu-tab.text').removeClass('open').addClass('open');
		}
		else if ($this.hasClass('use-upload')) {
			$(openTabSelector).removeClass('open');
			$('.menu .hotspot-view.open .menu-tab.upload').removeClass('open').addClass('open');
		}
	};

	// get the current perspective that is being used
	var getCurrentPerspective = function () {
		return currentPerspective;
	};

	// get the current hotspot that is being used
	var getCurrentHotspot = function () {
		return currentHotspot;
	};

	// get the current template that is being previewed
	var getCurrentTemplate = function () {
		var formState = hotspotFormStateReader.readHotspotFormState();
		var template = loadedTemplates.getTemplate(formState.TemplateID);
		return template;
	};

	var initGuide = function () {
		showGuide = true;
	};

	return {
		init: init,
		initGuide: initGuide,
		getCurrentPerspective: getCurrentPerspective,
		getCurrentHotspot: getCurrentHotspot,
		getCurrentTemplate: getCurrentTemplate
	};
});
// module that handles initializing one view project builds when the customer is editing an existing build
define('oneViewInitializer', ['viewData', 'jquery', 'templateData', 'messaging', 'loadedTemplates', 'templateForm', 'hotspotPreview', 'imageData', 'underscore', 'imageSelectorFieldControl'],
	function (viewData, $, templateData, messaging, loadedTemplates, templateForm, hotspotPreview, imageData, _, imageSelectorFieldControl) {
	'use strict';

	// initialize the private variables
	var previewTimer = 0;

	// request the template data for the build in question
	var init = function () {
		var templateID = viewData.OneViewTemplateID;

		// make the template data request
		templateData.getTemplate(templateID).done(templateGrabbed);

		messaging.hotspotPreviewRequested.subscribe(hotspotPreviewRequested);

		messaging.addToCartButtonClicked.subscribe(addToCartButtonClicked);
	};

	// template data api response handler
	var templateGrabbed = function (template) {
		// load the template into the library
		loadedTemplates.loadTemplate(template);

		// if the template has a field guide image, initialize it
		if (template.FieldGuideImage)
			hotspotPreview.initGuide();

        var $templateFormContainer = $('.project-ui .template-form');

        // added for responsive
        $('.project-ui').addClass('active-fields');
       

		// initialize template form
		templateForm.initializeForm($templateFormContainer, template);

		// if includes a saved build, initialize the values
		if (viewData.SavedBuild) {
			var hotspot = viewData.SavedBuild.Hotspots[0];
			hotspot.Fields.forEach(function (buildField) {
				var field = _.find(template.Fields, function (field) { return field.UUID === buildField.UUID; });
				var option = _.find(field.FieldOptions, function (option) { return option.UUID === buildField.ActiveOptionUUID; });
                initializeField(buildField, field, option);               
			});           
            // Notes field
            if (hotspot.Notes != undefined && hotspot.Notes != null && hotspot.Notes != '') {
                $('.field-notes .notes-container textarea').text(hotspot.Notes);
            }


			// apply the hotspot
			$('.hotspot-view').addClass('applied');

			// publish a hotspot preview request message
			messaging.hotspotPreviewRequested.publish();

			// this forces the notes to change in the review pane
            $('.field-notes textarea').change(hotspotPreviewRequested);

            // if there is at least 1 hotspot applied than show the review content instead of the project help text
            if ($('.hotspot-content-container .hotspot-view.applied').length > 0) {
                $('.menu .template-form').addClass('hide');
                $('.menu .navigation-wrapper').addClass('hide');
                $('.menu .review-container').removeClass('hide');
            }
		}
		else {
			// apply the hotspot
			$('.hotspot-view').addClass('applied');

			// publish a hotspot preview request message
			messaging.hotspotPreviewRequested.publish();

			// this forces the notes to change in the review pane
			$('.field-notes textarea').change(hotspotPreviewRequested);
        }

        // hide back button in first field
        $('.hotspot-view.open .navigation-wrapper .field-nav-button.back, .hotspot-view.open .navigation-wrapper .field-nav-button.next').addClass('one-view-first-field');

        // change button copy
        //if (viewData.SavedBuild != null) {
        //    $('.review-content .add-to-cart').html('SAVE TO CART')
        //}


		//if (viewData.Automated) {
		//	setTimeout(
		//		function () {
		//			$('.add-to-cart').click();
		//		}, 5000);

		//}
	};

	// applies the field data for a hotspot to the hotspot form inputs
    var initializeField = function (buildField, field, option) {
        var $field = $('.template-form .field[data-id=' + buildField.UUID + ']');
		var $fieldOption = $field.find('.field-option[data-id=' + buildField.ActiveOptionUUID + ']');
		$field.find('.field-option-select').val(buildField.ActiveOptionUUID).change();
		if ([2, 3, 4, 5, 6, 7, 8, 9, 13, 14].indexOf(buildField.Type) != -1) {	// text field type
			$fieldOption.find('.text-format-select').val(buildField.SelectedOption.ActiveFormatUUID).change();
			$fieldOption.find('.Text-field .customizable-item-wrapper').addClass('active');
			$fieldOption.find('.Text-field input.field-option-text-input').val(buildField.Text).change();
			$fieldOption.find('.Color-field input').val(buildField.ColorID).change();
			$fieldOption.find('.OutlineColor-field input').val(buildField.OutlineColorID).change();
			$fieldOption.find('.ShadowColor-field input').val(buildField.ShadowColorID).change();
		}
		else if (buildField.Type == 1) {	// image field type
			// if imageid is 0, then no image is selected
			if (buildField.ImageID != 0)
            {
                $field.addClass('saved-build-first-view');
				// in order to properly apply the image, it must be retrieved from the image data api
				imageData.getImage(buildField.ImageID).done(function (image) {
					// apply the selected image to the field
                    imageSelectorFieldControl.applySelectedImageToField($field.find('.field-option-image-control'), image);
					// loop over each replaced color and initialize the value
					buildField.ReplacedColors.forEach(function (replacedColor) {
						var replaceableColorID = replacedColor.ID;
						var colorID = replacedColor.ColorID;
						$fieldOption.find('.field-option-image-color-control[data-replaceableid=' + replaceableColorID + '] input[type=hidden]').val(colorID).change();
                    });  
                });               
			}
		}
		else if (buildField.Type === 11) {// custom select option
			buildField.SelectedOptions.forEach(function (selectedCustomOption) {
				$fieldOption.find('input[data-sku=' + selectedCustomOption.Sku + ']').prop('checked', true);	
			});
        }
        else if (buildField.Type === 12) {// custom image         
            $fieldOption.find('.custom-image-content-wrapper input.custom-image-id').val(buildField.CustomImage.CloudinaryId);
            $fieldOption.find('.custom-image-content-wrapper input.custom-image-version').val(buildField.CustomImage.Version);
		}   

		else if (buildField.Type === 15) {// // salesperson/NS sku   
			// sku 1
			$fieldOption.find('.NS-Sku1-Description').html(buildField.NetSuiteSalesPersonData.Sku1Description);
			$fieldOption.find('.NS-Sku1-Description-Hidden').html(buildField.NetSuiteSalesPersonData.Sku1Description);
			$fieldOption.find('.NS-Sku1-Price').val(buildField.NetSuiteSalesPersonData.Sku1Price);
			$fieldOption.find('.NS-Sku1-Price-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1Price);
			$fieldOption.find('.NS-Sku1-Cost').val(buildField.NetSuiteSalesPersonData.Sku1Cost);
			$fieldOption.find('.NS-Sku1-Cost-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1Cost);
			$fieldOption.find('.NS-Sku1-Hidden').val(buildField.NetSuiteSalesPersonData.Sku1);
			$fieldOption.find('.NS-Sku1').val(buildField.NetSuiteSalesPersonData.Sku1);
			$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');

			// sku 2
			$fieldOption.find('.netSuite-sku2-option-wrapper').removeClass('hide');
			if (buildField.NetSuiteSalesPersonData.Sku2 != null && buildField.NetSuiteSalesPersonData.Sku2 != '') {
				$fieldOption.find('#NetSuite-Sku2-Option').click();
				$fieldOption.find('.NS-Sku2-Description').html(buildField.NetSuiteSalesPersonData.Sku2Description);
				$fieldOption.find('.NS-Sku2-Description-Hidden').html(buildField.NetSuiteSalesPersonData.Sku2Description);
				$fieldOption.find('.NS-Sku2-Price').val(buildField.NetSuiteSalesPersonData.Sku2Price);
				$fieldOption.find('.NS-Sku2-Price-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2Price);
				$fieldOption.find('.NS-Sku2-Cost').val(buildField.NetSuiteSalesPersonData.Sku2Cost);
				$fieldOption.find('.NS-Sku2-Cost-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2Cost);
				$fieldOption.find('.NS-Sku2-Hidden').val(buildField.NetSuiteSalesPersonData.Sku2);
				$fieldOption.find('.NS-Sku2').val(buildField.NetSuiteSalesPersonData.Sku2);
				$fieldOption.find('.field-option-netsuite-control').addClass('valid-NS-sku');
			} else {
				$fieldOption.find('.NS-Sku2-Cost').val('');
			}
		}
	};

	// hotspot preview requested subscription handler
	var hotspotPreviewRequested = function () {
		clearTimeout(previewTimer);
		// wait a specific amount of time before publishing an apply button click message
		previewTimer = setTimeout(function () {
			messaging.applyButtonClicked.publish();
		}, 1000);
	};

	// add to cart click handler
	var addToCartButtonClicked = function () {
		messaging.applyPreview.publish();
	};

	return {
		init: init
	};
});
require(['hotspotPreview', 'menu', 'templateForm', 'review', 'templateGroups', 'loadedTemplates', 'imageSelectorFieldControl', 'addToCart', 'applyHotspot', 'oneViewInitializer', 'commonColorClick', 'overlay'], function
    (hotspotPreview, menu, templateForm, review, templateGroups, loadedTemplates, imageSelectorFieldControl, addToCart, applyHotspot, oneViewInitializer, commonColorClick, overlay) {
	'use strict';

    var init = function () {
        // add overlay to prevent unwanted clicks
        overlay.addOverlay();
        setTimeout(function () {
            overlay.removeOverlay();
        }, 2000);

		// run init functions for relevant modules
		review.init();
		menu.init();
		hotspotPreview.init();
		templateGroups.init();
		templateForm.init();
		loadedTemplates.init();
		imageSelectorFieldControl.init();
		addToCart.init();
		applyHotspot.init();
		applyHotspot.disableValidation();

		oneViewInitializer.init();

        review.refreshReviewUI();
     
	};

	init();
});